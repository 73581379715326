// Left panel
export const LEFT_PANEL_BLOCKS = "Blocks";
export const LEFT_PANEL_BLOCKS_CONTENT = "Content";
export const LEFT_PANEL_BLOCKS_CTA = "CTA";
export const LEFT_PANEL_BLOCKS_FEATURE = "Feature";
export const LEFT_PANEL_BLOCKS_FEATURE_GRID = "Feature Grid";
export const LEFT_PANEL_BLOCKS_FOOTER = "Footer";
export const LEFT_PANEL_BLOCKS_FORM = "Form";
export const LEFT_PANEL_BLOCKS_HEADER = "Header";
export const LEFT_PANEL_BLOCKS_HERO = "Hero";
export const LEFT_PANEL_BLOCKS_LOGO = "Logo";
export const LEFT_PANEL_BLOCKS_HTML = "HTML";
export const LEFT_PANEL_BLOCKS_NAV = "Navigation";
export const LEFT_PANEL_BLOCKS_PRICING = "Pricing";
export const LEFT_PANEL_BLOCKS_TEAM = "Team";
export const LEFT_PANEL_BLOCKS_TESTIMONIAL = "Testimonials";
export const LEFT_PANEL_DATA_BD = "Data";
export const LEFT_PANEL_PAGES = "Pages";
export const LEFT_PANEL_SETTINGS = "Settings";
export const LEFT_PANEL_THEME = "Theme";
export const LEFT_PANEL_BLOCK_CARDS = "Dynamic Cards";
export const LEFT_PANEL_BLOCK_NAVBAR = "Nav Bars";
