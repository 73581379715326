import { twdClass } from "../../constants/tailwind.constants";
import {
  UPDATE_STYLE_ID,
  UPDATE_STYLE_PART,  
  UPDATE_LIST_INDEX,
  UPDATE_ADVANCED_ID,
} from "../../constants/action.constants";
import { useDispatch } from "react-redux";
import FaIcons from "../common/FaIcons";
import ActionBtn from "../common/ActionButton";
import { useState, useEffect } from "react";
import RenderElements from "./RenderElements";
import {
  StyleBtn,
  CollapseBtn,
  SortBtn,
  AddItemBtn,
  DeleteItemBtn,
  SettingsBtn,
} from "../ui/presets";
import { set, get, assignIn, replace, isEmpty, cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";


import GenerateGroup from "./GenerateGroup"
import {hideshowElement} from "./elements/util";
import Sortable from "../studio/pages/com/Sortable";
import {arrayMove as aM} from "../studio/pages/com/Sortable";


const LC1 = twdClass(
  "px-1 text-sm border border-transparent hover:bg-gray-200 mb-2 rounded-xl bg-gray-50 "
);
const LC1b = twdClass("px-1 text-sm border mb-2 rounded-xl bg-gray-100 ");
const LC2 = twdClass("pl-3 pr-0 flex items-center");
const LC3 = twdClass("text-gray-500 cursor-pointer flex-1");
const LC4 = twdClass("cursor-pointer ml-3");

const ListComponent = ({ element, data, updateElementValue, inputArgs, listArgs={}, expanded=true, sortable=false}) => {
  if (!inputArgs) inputArgs = { inputStyle: 1 };
  const { topContent=''} = listArgs
  const [listElement, setListElement] = useState(element);
  const [dataId, setDataId] = useState(null);
  const [isActiveItem, setActiveItem] = useState(null);
  const [isSortItems, setSortItems] = useState(false);


  useEffect(() => {
    if (dataId !== null) {
      setListElement((config) => ({ ...config, elements: [] }));
    }
    setDataId(data.metaDataId);
    setTimeout(() => {
      const lst = get(data, element.updateTo);
      if (lst) {
        lst.forEach((item) => addElement());
      }
    }, 100);
  }, [data.metaDataId]);

  const dispatch = useDispatch();
  const { label, hasStyleProps, styleElementId, mockElements } = listElement;

  useEffect(() => {
    //console.log("USEEFFECT")
    //console.log("listElement", listElement)
    listElement.elements.forEach((item) => {
      item.elements.forEach((rec) => {
        const dataValue = get(data, rec.updateTo);
        if (isEmpty(dataValue)) {
          let updateToKey = rec.updateTo;
          let values = rec.defaultValue;
          if (!isEmpty(rec.extraPropValues)) {
            const indexMatch = updateToKey.match(/\[(.*?)\]/);
            if (indexMatch !== null) {
              updateToKey = replace(
                rec.extraPropUpdateTo,
                "[i]",
                `[${indexMatch[1]}]`
              );
              values = cloneDeep(rec.extraPropValues);
            }
          }
          //console.log("USEEFFECT", updateToKey, values)
          //console.log("listElement", updateToKey, values)
          updateElementValue(updateToKey, values);
        }
      });
    });
  }, [JSON.stringify(listElement.elements)]);
  

  const addElement = () => {
    setListElement((state) => {
      const updatedElms = [...state.elements, mockElements].map((item, ix) => {
        let { elements } = item;
        elements = elements.map((e) => {
          const updateTo = replace(e.updateTo, "[i]", `[${ix}]`);
          return { ...e, updateTo };
        });
        return { ...item, elements };
      });
      return { ...state, elements: updatedElms };
    });
  };

  const removeElement = (index) => {
    setListElement((state) => ({ ...state, elements: [] }));
    const lst = get(data, listElement.updateTo);
    //console.log("delete", listElement.updateTo, lst)
    const list = lst.filter((_, i) => i !== index);
    //console.log("list", listElement.updateTo, list)
    list.forEach(() => addElement());
    updateElementValue(listElement.updateTo, list);
  };

  const [show, setShow] = useState(expanded);
  const CL_ADD =
    "bg-white rounded-full overflow-hidden shadow-md transform duration-150 hover:-translate-y-0.5 active:translate-y-0 hover:shadow-lg active:shadow-sm hover:opacity-100";
  
  const generateElements = (item) => {
    const { idx, element, data, updateElementValue, inputArgs, forSorting=false } = item;
    //console.log('list generate element', element)
    /*const { hideBasedElemVal, showBasedElemVal } = element;
    
    let hideElement = false;
    if (hideBasedElemVal) {
      const { elem, value } = hideBasedElemVal;
      let elem_= replace(elem, "[i]", `[${idx}]`);
      hideElement = get(data, elem_) === value;
    }
    if (showBasedElemVal) {
      const { elem, value } = showBasedElemVal;
      let elem_= replace(elem, "[i]", `[${idx}]`);
      hideElement = get(data, elem_) !== value;      
      //console.log('list generate show', hideElement,  elem, elem_, value )
      //console.log('list generate show data', data)
      //console.log('list generate show selected', get(data, elem_)  )
    }*/
    let hideElement = hideshowElement({type:'list', index:idx, data, element}) 
    const updateElementValue2 = (to, value) => {      
      let to_= replace(to, "[i]", `[${idx}]`);
      //console.log(idx, to, to_, value)
      return updateElementValue(to_, value)
    }   
    if (hideElement) {
      return <></>;
    }    
    if(element.inputType=="group"){
      //console.log("GROUP IDX", element.label, idx)
      return (
       <GenerateGroup  {...item} {...{idx, updateElementValue:updateElementValue}}/>
     );
   }
    return (
      <>      
      <RenderElements {...item} {...{updateElementValue:updateElementValue2}}/>
      </>
    )
  }
  const itam2 = ({item, index, forSorting=false}) => {
    let CL_WRAP =
      isActiveItem === index
        ? "bg-white border-2 border-gray-200 hover:border-gray-500"
        : "bg-white bg-opacity-50 hover:bg-opacity-100 hover:border-gray-200 border-2 border-white";
    let CL_HEADER =
      isActiveItem === index ? "font-semibold " : "font-semibold ";
    let CL_CONTENT = isActiveItem === index ? "" : "";
    let CL_ICON_DELETE = isActiveItem === index ? "opacity-50" : "opacity-10";
    let CL_ICON = isActiveItem === index ? "opacity-50" : "opacity-25";
    let CL_NUMBER = isActiveItem === index ? "text-white bg-black" : "bg-white";
    let CL_STYLE = isActiveItem === index ? "opacity-100" : "opacity-10";

    /* PARTS */

    const PartEl_style = (rec, index) => (
      <StyleBtn     
        className={"opacity-25 group-hover:opacity-100"}     
        onClick={() => {
          //console.log(rec, index)
          dispatch({ type: UPDATE_LIST_INDEX, id: index });
          dispatch({
            type: UPDATE_STYLE_ID,
            id: rec.styleElementId,
          });
        }}
      >
      </StyleBtn>
    );

    const PartItem_style = (index) => (
      <div className={""}>
        <StyleBtn
          onClick={() => {
            dispatch({ type: UPDATE_LIST_INDEX, id: index });
            dispatch({
              type: UPDATE_STYLE_ID,
              id: item.styleElementId,
            });
          }}
        ></StyleBtn>
      </div>
    );

    const handleItemClick = () => {
      setActiveItem(index);
    };

    let out= (
      <div
        key={index}
        onClick={handleItemClick}
        className={twdClass(
          `relative list rounded-xl  mb-2 flex flex-col p-1  ${CL_WRAP}`
        )}
      >
        
        <div className={twdClass(`flex items-center pr-2 mb-2 ${CL_HEADER}`)}>
          <div className={twdClass(`px-1 py-1 flex items-center flex-grow `)}>
            <span
              className={twdClass(
                `${CL_NUMBER} shadow-md rounded-full w-6 h-6 flex items-center justify-center mr-2`
              )}
            >
              {index + 1}
            </span>
            Item
          </div>          
          {!forSorting && <div className={twdClass(`${CL_STYLE} hover:opacity-100`)}>
            {item.hasStyleProps && PartItem_style(index)}
          </div>}
        </div>
        <div className={twdClass(`flex-1 ${CL_CONTENT}`)}>          
          {item.elements.map((rec, idx) => {
            let action =
              rec.hasStyleProps === true ? PartEl_style(rec, index) : "";            
            return (
              <div
                key={idx}
                className={twdClass("mb-1 w-full bg-white relative group")}
              >
                {/*rec.hasStyleProps && PartEl_style(rec, index)*/}
                {/*<RenderElements
                  element={rec}
                  data={data}
                  updateElementValue={updateElementValue}
                  inputArgs={{
                    action: action,
                    ...inputArgs,
                    listIndex: index,
                  }}
                />*/}
                {generateElements({
                  idx:index,
                  element:rec,
                  data:data,
                  updateElementValue:updateElementValue,
                  inputArgs:{
                    action: action,
                    ...inputArgs,
                    listIndex: index,
                  }
                })}
                {/*<GenerateElements
                  idx = {index}
                  element={rec}
                  data={data}
                  updateElementValue={updateElementValue}
                  inputArgs={{
                    action: action,
                    ...inputArgs,
                    listIndex: index,
                  }}
                />*/}
              </div>
            );
          })}
        </div>
        {!forSorting && 
        <div className={twdClass("flex items-center py-1 px-1")}>
          <div className={twdClass("flex items-center")}>
            <div className={twdClass(`${CL_ICON_DELETE} hover:opacity-100`)}>
              <DeleteItemBtn onClick={() => removeElement(index)} />
            </div>
            {/*<button
              className={twdClass(`w-6 text-center`)}
              onClick={() => removeElement(index)}
              data-tip="Delete"
            >              
              <div
                className={twdClass(
                  `${CL_ICON_DELETE} hover:opacity-100`
                )}
              >
                <img
                  alt=""
                  width="15px"
                  height="auto"
                  src="/assets/images/icons/icon_delete.png"
                />
              </div>
            </button>
            <ReactTooltip place="top" effect="solid" />   */}
          </div>
          <div className={twdClass("flex-grow")}></div>
        </div>
        }
        {forSorting && <div  className={twdClass(`absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-25 z-10 -mx-1 -my-1`)}></div>}
      </div>
    );
    
      
    return out
  };

  
  
  return (
    <div className={show ? LC1b : LC1}>      
      <div className={LC2} onClick={() => setShow(!show)}>
        <div className={LC3}>
          <div
            className={twdClass(
              "text-gray-800 font-semibold flex items-center "
            )}
          >
            <img
              width="20px"
              className={twdClass("mr-2")}
              src={"/assets/images/flex-align-top-01.svg"}
            />
            {label} {sortable ? '#fasty' : "no"}
          </div>
        </div>
          
        {/*<div data-tip="Add Item" className={`${!show ? twdClass("hidden") : ""} LC4`} onClick={(e)=>{e.stopPropagation(); addElement(e)}}>
         
          <div className={twdClass(CL_ADD)}>
            <img alt="" width="24px" src="/assets/images/icons/icon_add.svg" />
          </div>
        </div>
        <ReactTooltip place="top" effect="solid" />*/}

        <div className={`${LC4} `}>
          {hasStyleProps && (
            <StyleBtn
              onClick={(e) => {
                e.stopPropagation();
                dispatch({ type: UPDATE_STYLE_ID, id: styleElementId });
                dispatch({ type: UPDATE_STYLE_PART, part: null });
              }}
            />
          )}
        </div>
        <CollapseBtn className={"ml-1"} isOpen={show} />
      </div>
      <div className={`${!show ? twdClass("hidden") : ""}`}>
        {topContent && <div className={twdClass(`px-3`)}>
          {topContent}
        </div>}
        <div className={twdClass(`flex items-center`)}>
          <div className={twdClass(`mt-4 mb-4 ml-3`)}>
            <AddItemBtn onClick={addElement} />
          </div>
          {sortable && <div className={twdClass(`mt-4 mb-4 ml-3`)}>
            <SortBtn onClick={()=>{setSortItems(!isSortItems)}} />
          </div>}
        </div>       
        
        {isSortItems && <ListForSorting {...{itam2, listElement, setListElement, data, updateElementValue}}/>}      
        {!isSortItems && listElement.elements.map((item, index) => {
          return itam2({item, index});          
        })}

        <div
          className={twdClass(
            `${listElement.elements.length < 5 ? "hidden" : ""} ml-3 mb-4 mt-4`
          )}
        >
          <AddItemBtn onClick={addElement} />
        </div>
      </div>      
      {/*<div className={twdClass("flex justify-end px-1")}>        
        <ActionBtn onClick={addElement} bcolor={"light"}>
          <div>
            <img alt="" width="24px" src="/assets/images/icons/icon_add.svg" />
          </div>
          <div>Add Item</div>
        </ActionBtn>
      </div>*/}
    </div>
  );
};

export default ListComponent;



const ListForSorting = ({listElement, itam2, setListElement, data, updateElementValue}) => {
  let sortList = [
    {page_id:1, page_name:"fasty 1"},
    {page_id:2, page_name:"nasty 2"},
    {page_id:2, page_name:"costy  3"}
  ]

  const [isSortList, setSortList] = useState(sortList);
  
  let sortSelected=1
  
  let items_c='relative flex flex-col'
  const item_cb=({value, i})=>{
    return(
      <div
        className={twdClass(
          `mb-1 pl-2 pr-1 py-1 text-sm rounded-2xl cursor-pointer hover:bg-white w-full flex items-center select-none ${
            sortSelected === value.styleElementId ? "bg-white" : ""
          }`
        )}          
      >
        <img
          width="18px"
          src="/assets/images/MathSign.svg"
          className={twdClass("mr-2")}
          alt=""
        />
        <div className={twdClass("flex-grow")}>{itam2({item:value, index:i, forSorting:true})}</div>
      </div>
    )
  }
  const cb = ({lst, oldIndex, newIndex}) => {
    
    let d=get(data, listElement.updateTo)
    let d2 = aM(d,  oldIndex, newIndex)
    //console.log("d", d)
    //console.log("d2", d2)  
    updateElementValue( listElement.updateTo, d2);
    /*let lst_p=lst.map((item, ix) => {
      let { elements } = item;
      elements = elements.map((e) => {        
        let updateTo=e.updateTo.replace(
          /\[(.*?)\]/g, 
          `[${ix}]`
        )
        //const updateTo2 = replace(e.updateTo, "[i]", `[${ix}]`);
        return { ...e, updateTo, updateTo_bk:e.updateTo };
      });
      return { ...item, elements };
    });
  
    console.log("lstProcessed", lst_p)
    setListElement((state) => {     
      return { ...state, elements: lst_p};
    });  */
  }

  const item_cb2=({value, i})=>{
    return(
      <div
        className={twdClass(
          `mb-1 pl-2 pr-1 py-1 text-sm rounded-2xl cursor-pointer hover:bg-white w-full flex items-center select-none ${
            sortSelected === value.styleElementId ? "bg-white" : ""
          }`
        )}          
      >
        <img
          width="18px"
          src="/assets/images/MathSign.svg"
          className={twdClass("mr-2")}
          alt=""
        />
        <div className={twdClass("flex-grow")}>{value.page_name}</div>
      </div>
    )
  }
  const cb2 = ({lst, oldIndex, newIndex}) => {
    //console.log("lst", lst) 

    setSortList((state) => {     
      return lst;
    });  
  }
  
  return(
    <div className={twdClass( "w-full")}>
      <Sortable {...{list_items:listElement.elements, cb, item_cb, items_c}}/>
    </div>
  )
}
