import { get } from "lodash";
import { useState, useEffect } from "react";
import { twdClass } from "../../../constants/tailwind.constants";

const TextareaInput = ({ element, data, updateElementValue }) => {
  const [value, setValue] = useState();
  const { updateTo, defaultValue } = element;

  useEffect(() => {
    /* const dataValue = get(data, updateTo);
    let value = "";
    if (!isEmpty(dataValue)) {
      value = dataValue;
    } else if (!isEmpty(defaultValue)) {
      value = isString(dataValue) ? defaultValue : get(defaultValue, updateTo);
      debugger;
    } */
    //const str =  && isString(dataValue) ? dataValue;

    const dataValue = get(data, updateTo);
    const value = dataValue ? dataValue : defaultValue;
    setValue(value);
  }, [get(data, updateTo)]);

  return (
    <textarea
      className={twdClass(
        "w-full px-2 py-1 border border-gray-100 hover:border-gray-300 rounded-md bg-gray-50 hover:bg-gray-100 focus:bg-gray-200 active:bg-gray-200"
      )}
      onChange={(e) => {
        setValue(e.target.value);
        updateElementValue(updateTo, e.target.value);
      }}
      value={value}
    ></textarea>
  );
};

export default TextareaInput;
