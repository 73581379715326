import { twdClass } from "../../../constants/tailwind.constants";
import { LEFT_PANEL_METADATA_BLOCKS } from "./left.metadata";
import { Scrollbars } from 'react-custom-scrollbars';
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { ADD_BLOCK_TO_PAGE } from "../../../constants/action.constants";

const SP_CLS_1 = twdClass("flex -ml-4");
const SP_CLS_2 = twdClass("w-52 mt-4");
const SP_CLS_3 = twdClass("flex-1 pr-2 bg-white rounded-xl px-6 py-2 -mr-4 -mb-2");
const SP_CLS_4 = twdClass("p-2 pl-6 text-sm font-semibold rounded-tl-full rounded-bl-full hover:bg-white cursor-pointer flex items-center");
const SP_CLS_5 = twdClass("text-lg py-2 font-semibold");
const SP_CLS_6 = twdClass("bg-gray-50 rounded-xl hover:shadow-md active:shadow-xs transform  hover:-translate-y-px active:translate-y-0 p-1 cursor-pointer");
const SP_CLS_7 = twdClass("text-sm my-2 px-2 font-semibold");
const SP_CLS_8 = twdClass("shadow-sm");
const SP_CLS_9 = twdClass("text-sm mb-4");

const SectionsPanel = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);
  const { openRightPanel } = useSelector((state) => state.studioDetails);
  const { previewType } = useSelector((state) => state.studioView);

  useEffect(() => {
    setActiveMenu(LEFT_PANEL_METADATA_BLOCKS[0]);
  }, []);

  if (activeMenu === null) {
    return <></>;
  }
  return (
    <div className={`${SP_CLS_1} section-panel`}>
      <div className={SP_CLS_2}>
        {LEFT_PANEL_METADATA_BLOCKS.map((item, index) => {
          let {title, iconImg=''} = item
          const isActive = item.blockId === activeMenu.blockId;
          return (
            <div
              key={index}
              className={`${
                isActive ? twdClass("bg-white") : ""
              } ${SP_CLS_4}`}
              onMouseEnter={() => setActiveMenu(item)}
            >
              <img className={twdClass("mr-3")} src={`/assets/images/${iconImg}`}/>
              {item.title}
            </div>
          );
        })}
      </div>
      <div className={`sec-details-panel ${SP_CLS_3}`}>
        <Scrollbars style={{ width: '100%', height: '100%' }} autoHide>        
        <h1 className={SP_CLS_5}>{activeMenu.title}</h1>
        <div className={SP_CLS_9}>{activeMenu.description}</div>
        <div
          className={twdClass(
            `grid grid-cols-${activeMenu.showItemsRow} gap-4 `
          )}
        >
          
          {activeMenu &&
            activeMenu.subMenu.map((item, index) => {
              return (
                <div
                  key={index}
                  className={SP_CLS_6}
                  onClick={() => {
                    const data = cloneDeep(item.metadata);
                    data.metaDataId = new Date().getTime();
                    dispatch({ type: ADD_BLOCK_TO_PAGE, data });
                    smootScroll(openRightPanel && previewType === "desktop");
                  }}
                >
                  <img className={SP_CLS_8} alt="" src={item.image} />
                  <div className={SP_CLS_7}>{item.label}</div>
                </div>
              );
            })}
        </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SectionsPanel;

const smootScroll = (panel) => {
  setTimeout(() => {
    let frame = document.querySelector("#sly-preview-iframe");
    const cWind = frame.contentWindow;
    const siteSection = cWind.document.querySelectorAll(".site-section")[0];
    const transit = panel ? 0.75 : 1;
    let y=0
    if(siteSection && siteSection.offsetHeight && siteSection.lastElementChild.offsetHeight) {
      y =
      siteSection.offsetHeight * transit -
      (siteSection.lastElementChild.offsetHeight * transit + 130);
    }
    cWind.scrollTo({
      top: y,
      behavior: "smooth",
    });
  }, 100);
};
