import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  RESET_GENERATED
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import { saveProject, getProjectInfo, getGenerateDesign, promptChatGPTMini } from "../../../api/services";
import { toast } from "react-toastify";
import { Button} from "../../ui/com_ui";
import ToggleBtn from "../../common/ToggleBtn";
import Generate from '../../generate/Generate'


const GeneratePagePanel = (props) => {
  return (
    <Generate {...props}/>
  );
};

export default GeneratePagePanel;
